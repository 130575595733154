import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.less';
import Footer from './Header & Footer/footer';
import Header from './Header & Footer/header';
import mapRouter from './Router/router';
import { menu } from '../Misc/menuConfig';

export default function App(): JSX.Element {
    return (
        <Router>
            <Header />
            {mapRouter(menu)}
            <Footer />
        </Router>
    );
}
