import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faTimes,
    // faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import './header-footer.less';
import Menu from '../Menu/menu';
import { menu } from '../../Misc/menuConfig';

export default function Header(): JSX.Element {
    const [scrolled, setScrolled] = useState(true);
    const history = useHistory();
    const [menuToggle, setMenuToggle] = useState(false);
    const [resizing, setResizing] = useState(false);

    useEffect(() => {
        const unlisten = history.listen(() => setMenuToggle(false));
        function scrollHandler(): void {
            if (
                document.body.clientHeight -
                    window.innerHeight -
                    window.scrollY >
                    120 &&
                window.scrollY > 0
            ) {
                setScrolled(true);
            } else if (window.scrollY === 0) setScrolled(false);
        }
        let timeout = 0;
        function resizeHandler(): void {
            clearTimeout(timeout);
            setResizing(true);
            timeout = window.setTimeout((): void => setResizing(false), 400);
        }

        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('resize', resizeHandler);
        return (): void => {
            unlisten();
            window.removeEventListener('resize', resizeHandler);
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    return (
        <header className={scrolled ? 'scroll' : ''}>
            <div className='container'>
                <div className='topHeaderBar headerBar'>
                    <div className='container'>
                        {/* <button
                            aria-label='login'
                            type='button'
                            className='login'
                        >
                            <FontAwesomeIcon icon={faUserCircle} /> LOGIN
                        </button> */}
                    </div>
                </div>
                <div className='lowHeaderBar headerBar'>
                    <div className='container'>
                        <Menu
                            menuList={menu}
                            className={`${menuToggle ? 'open' : ''} ${
                                resizing ? 'resizing' : ''
                            }`}
                        />
                        <button
                            aria-label='toggle mobile menu'
                            type='button'
                            className='toggleMenu'
                            onClick={(): void => setMenuToggle(!menuToggle)}
                        >
                            <FontAwesomeIcon
                                icon={menuToggle ? faTimes : faBars}
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div className='header-placeholder' />
        </header>
    );
}
