import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer(): JSX.Element {
    return (
        <footer>
            <div className='container upper'>
                <div className='footerBar'>
                    <div className='container'>
                        <p>
                            Address: 15/F Prosperity Millennia Plaza, 663 King’s
                            Road, Quarry Bay, Hong Kong
                        </p>
                        <p>
                            Tel: <a href='tel:37009097'>3700 9097</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className='container lower'>
                <div className='footerBar'>
                    <div className='container'>
                        <span id='copyright'>
                            &#169; Copyright {new Date().getFullYear()} Optimal
                            Power
                        </span>
                        <div className='footer-link'>
                            <Link to='/about/privacy'>Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
