import { FunctionComponent, lazy } from 'react';

export type Menu = {
    name?: string;
    path?: string;
    component?: FunctionComponent;
    excludeFromMenu?: true;
    childNode?: Menu[];
    privateRoute?: true;
};

export const menu: Menu[] = [
    {
        name: 'Home',
        path: '/',
        component: lazy(() => import('../Components/Pages/Homepage/homepage')),
    },
    {
        name: 'Digital Soluation',
        childNode: [
            {
                name: 'iAccess',
                path: '/iAccess',
                component: lazy(
                    () => import('../Components/Pages/I & I/iAccess')
                ),
            },
            {
                name: 'iAudience',
                path: '/iAudience',
                component: lazy(
                    () => import('../Components/Pages/I & I/iAudience')
                ),
            },
        ],
    },
    {
        name: 'Gallery',
        childNode: [
            {
                name: '2020福布斯中国中日未来产业论坛',
                path: '/gallery/2020福布斯中国中日未来产业论坛',
                component: lazy(
                    () => import('../Components/Pages/Galleries/forbesForum')
                ),
            },
            {
                name: '2020福布斯中國創新峰會',
                path: '/gallery/2020福布斯中國創新峰會',
                component: lazy(
                    () => import('../Components/Pages/Galleries/forbesForum2')
                ),
            },
        ],
    },
    {
        name: 'Contact Us',
        path: '/contact',
        component: lazy(() => import('../Components/Pages/Contact Us/contact')),
    },
];
